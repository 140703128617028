import React , {useState,useEffect} from 'react'
import { useMediaQuery } from 'react-responsive';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { Link } from 'react-router-dom';

function Contact() {
    
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1224px)'
    })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    const [Nav, setNav] = useState(true)
    if(isTabletOrMobile){
         return (
<div className="mobil_studyo">
                <div className="nav_mobile">
                            <Link to="/">
                            <div className="log">
                              <img src="./img/creators1.png" alt=""/>
                            </div>
                            </Link>
                            
                            <div className="icons">
                              <ShoppingCartIcon></ShoppingCartIcon>
                              <PersonIcon></PersonIcon>
                              <SearchIcon></SearchIcon>
                              <div className="burger" onClick={(e)=>{
                                setNav(!Nav)
                              }}>
                                <div className="line"></div>
                                
                              </div>
                            </div>
                          </div>
                            <div className={Nav?"nav_scrol":"nav_scrol active"} >
                            <div className="row bul">
                              <div className="col-6 alt"><Link to="/studyo">Stüdyo
                              </Link></div>
                              <div className="col-6 alt"><Link to="/ekipman">Ekipman</Link></div>
                              
                              <div className="col-6 alt"><Link to="/biz">Biz</Link></div>
                              <div className="col-6 alt"><Link to="/iletisim">İletişim</Link></div>

                            </div>
                            </div>
                <div className="studyo_mobilcontent">
                    
                    <div className="mobil_contact">
                        <div className="row">
                            <div className="title">
                                <h1>Iletişim için</h1>
                            </div>
                            <div className="col-12">
                            <h3>Bursa Youtube Stüdyosu</h3>
                                            <p> <span>Adres</span> : Yeni Yalova cad. Altınova mah. No:428 Kat:10 Daire:39 Osmangazi / BURSA</p>
                                            <p> <span>Telefon</span> : 444-9-948</p>
                                            <p> <span>Mail</span> : info@creators.com.tr</p>
                            </div>
                            <div className="col-12">
                            <h3 >Bursa Youtube Stüdyosu</h3>
                                            <p> <span>Adres</span> : Brooklyn/New York</p>
                                            <p> <span>Telefon</span> : 444-9-948</p>
                                            <p> <span>Mail</span> : info@creators.com.tr</p>
                            </div>
                            <div className="col-12">
                            <h3>Bursa Youtube Stüdyosu</h3>
                                            <p> <span>Adres</span> : Yeni Yalova cad. Altınova mah. No:428 Kat:10 Daire:39 Osmangazi / BURSA</p>
                                            <p> <span>Telefon</span> : 444-9-948</p>
                                            <p> <span>Mail</span> : info@creators.com.tr</p>
                            </div>

                        </div>
                    </div>
                    
                    <div className="contact">
                    <div className="kilit">
                    <div className="footer">
                                
                                <div className="mailing">
                                    
                                    <div className="input">
                                    <input type="text" placeholder="E-Mailinizi Yazın"/>
                                    </div>
                                    <div className="button">
                                    Onayla
                                    </div>
                                </div>
                    
                                

                                    

                                <div className="width">
                                <a href="http://www.creators.com.tr/">Creators</a>
                                <a href="/iletisim">İletişim</a>
                                <a href="#">Basın</a>
                                <a href="/iletisim">Yardım</a>
                              </div>
                              <div className="width">
                                <a href="#">Gizlilik</a>
                                <a href="#">Partner</a>
                                <a href="#">Portal</a>
                                
                              </div>
                                
                                <span>
                                @2002-2021 Yiğit Holding , Bütün hakları Saklıdır. 
                                </span>
                    
                                <ul>
                                    <li><YouTubeIcon style={{ fontSize: 40 }}></YouTubeIcon></li>
                                    <li><InstagramIcon style={{ fontSize: 40 }}></InstagramIcon></li>

                                    
                                </ul>
                    
                            </div>
                    </div>
                    </div>
                
                </div>





            </div> 

        )
    }else{
        return (
            <div className="App">
                            <div className="nav">
                                <Link to="/"><div className="log">
                                    f<img src="./img/creators.png" alt=""/>
                                </div></Link>
                                <ul>
                                <Link to="/studyo"><li>Stüdyo</li></Link>
                                <Link to="/ekipman"><li>Ekipman</li></Link>
                                
                                <Link to="/biz"><li>Biz</li></Link>
                                <Link to="/iletisim"><li>İletişim</li></Link>
                                <li><ShoppingCartIcon></ShoppingCartIcon></li>
                                <li><PersonIcon></PersonIcon></li>
                                <li><SearchIcon></SearchIcon></li>
                                <li><div className="burger" onClick={(e)=>{
                                    setNav(!Nav)
                                }}>
                                    <div className="line"></div>
                                    
                                </div></li>
                                
                                
                                
                                
                                
                                </ul>
                                <div className="icons">
                                <img src="./img/red.png" height="75" alt=""/>
                                </div>
                            </div>


                            

                        <div className="contact">
                        <div className="maincontent">
                                <div className="title">
                                    <h1>
                                        İletişim
                                    </h1>
                                    
                                </div>
                                <div className="text">
                                    <div className="row">
                                        <div className="col-lg-4 col-sm-12 col-md-12">
                                            <h3>Bursa Youtube Stüdyosu</h3>
                                            <p> <span>Adres</span> : Yeni Yalova cad. Altınova mah. No:428 Kat:10 Daire:39 Osmangazi / BURSA</p>
                                            <p> <span>Telefon</span> : 444-9-948</p>
                                            <p> <span>Mail</span> : info@creators.com.tr</p>
                                        </div>
                                        
                                        <div className="col-lg-4 sad col-sm-12 col-md-12">
                                        <h3>New York Youtube Stüdyosu</h3>
                                        <p>Adres : Brooklyn/New York</p>
                                            <p>Telefon : 444-9-948</p>
                                            <p>Mail : info@creators.com.tr</p>
                                        </div>
                                        
                                        <div className="col-lg-4 col-sm-12 col-md-12">
                                        <h3>Sydney Youtube Stüdyosu</h3>
                                            <p>Adres : Yeni Yalova cad. Altınova mah. No:428 Kat:10 Daire:39 Osmangazi / BURSA</p>
                                            <p>Telefon : 444-9-948</p>
                                            <p>Mail : info@creators.com.tr</p>
                                        </div>
                                    </div>
                                </div>
                        </div>
                            <div className="footer">
                                
                                <div className="mailing">
                                    <div className="al">
                                    Biz Size Ulaşalım
                                    </div>
                                    <div className="input">
                                    <input type="text" placeholder="E-Mailinizi Yazın"/>
                                    </div>
                                    <div className="button">
                                    Onayla
                                    </div>
                                </div>
                    
                                <ul>

                                    

                                <li><a href="http://www.creators.com.tr/">Creators</a></li>
                                  <li><Link to="/iletisim">İletişim</Link></li>
                                  <li>Basın</li>
                                  <li><Link  to="/iletisim">Yardım</Link></li>
                                  <li>Gizlilik</li>
                                  <li>Partner</li>
                                  <li>Portal</li>
                                </ul>
                                <span>
                                @2002-2021 Yiğit Holding , Bütün hakları Saklıdır. 
                                </span>
                    
                                <ul>
                                    <li><YouTubeIcon></YouTubeIcon></li>
                                    <li><InstagramIcon></InstagramIcon></li>

                                    
                                </ul>
                    
                            </div>
                        </div>
            </div>
        )
    }
   
}

export default Contact
