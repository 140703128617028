import React,{useState,useEffect} from 'react'
import './studyo.css'
import { useMediaQuery } from 'react-responsive';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import {Link} from 'react-router-dom'
function Studyo() {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 1224px)'
      })
      const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
      const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1224px)'
      })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
      const [Nav, setNav] = useState(true)
    if(isTabletOrMobile){
        return(
            <div className="mobil_studyo">
                <div className="nav_mobile">
                            <Link to="/">
                            <div className="log">
                              <img src="./img/creators1.png" alt=""/>
                            </div>
                            </Link>
                            
                            <div className="icons">
                              <ShoppingCartIcon></ShoppingCartIcon>
                              <PersonIcon></PersonIcon>
                              <SearchIcon></SearchIcon>
                              <div className="burger" onClick={(e)=>{
                                setNav(!Nav)
                              }}>
                                <div className="line"></div>
                                
                              </div>
                            </div>
                          </div>
                            <div className={Nav?"nav_scrol":"nav_scrol active"} >
                            <div className="row bul">
                              <div className="col-6 alt"><Link to="/studyo">Stüdyo
                              </Link></div>
                              <div className="col-6 alt"><Link to="/ekipman">Ekipman</Link></div>
                              
                              <div className="col-6 alt"><Link to="/biz">Biz</Link></div>
                              <div className="col-6 alt"><Link to="/iletisim">İletişim</Link></div>

                            </div>
                            </div>
                <div className="studyo_mobilcontent">
                    <div className="studyo_firsthalf">
                        <img src="./img/stüdyo/studyo1.png" alt=""/>
                        <h1>Türkiyenin ilk Youtube Stüdyosuna Hoşgeldiniz.</h1>
                    </div>
                    <div className="studyo_secondhalf">
                        <img src="./img/osmo.jpg" alt=""/>
                        <div className="studyo_content">
                        <p>
                        <b>Creators</b> Türkiye'de bulunan başarılı yeteneklerimize yol arkadaşlığı ediyor aynı zamanda uzun zamandır edindiği deneyimleri onları geliştirmek ve daha ileriye götürmek için kullanıyor.

 <br/>

<b>Creators</b> Sosyal medyada video içeriği üreten veya üretmeyi hedefleyen yetenekli video bloggerlarımız için Profesyonel hizmetler sağlıyor ve onların yanında yer almaktan gurur duyuyor.


                        </p>
                        </div>
                    </div>
                      <div className="sec">
                          <p></p>
                      </div>

                    <div className="thirdhalf">
                        <div className="row ">
                          <div className="col-12 cont">
                          <h1>Dijital Medya Stüdyo ve Prodüksiyon</h1>
                        <p> 

İhtiyacınız için verilen hizmetler arasında Yeni medya stüdyo ve prodüksiyon hizmetimizde yer almaktadır. Profesyonel stüdyo ve prodüksiyon hizmetimiz ile emin ellerdesiniz.</p>
                          </div>
                          <div className="col-12 cont">
                          <h1>Menajerlik Hizmeti</h1>
                        <p>İhtiyacınız için verilen hizmetler arasında Yeni medya stüdyo ve prodüksiyon hizmetimizde yer almaktadır. Profesyonel stüdyo ve prodüksiyon hizmetimiz ile emin ellerdesiniz.</p>
                          </div>
                          <div className="col-12 cont">
                          <h1>Mcn'lik Hizmeti</h1>
                        <p>Mcn'lik Hizmetimiz ile Dijital medya'ya ayak uydurmak artık Creators ile beraber daha eğlenceli.</p>
                          </div>
                        </div>
                    </div>
                    <div className="contact">
                    <div className="kilit">
                    <div className="footer">
                                
                                <div className="mailing">
                                    
                                    <div className="input">
                                    <input type="text" placeholder="E-Mailinizi Yazın"/>
                                    </div>
                                    <div className="button">
                                    Onayla
                                    </div>
                                </div>
                    
                                

                                    

                                <div className="width">
                                <a href="http://www.creators.com.tr/">Creators</a>
                                <a href="/iletisim">İletişim</a>
                                <a href="#">Basın</a>
                                <a href="/iletisim">Yardım</a>
                              </div>
                              <div className="width">
                                <a href="#">Gizlilik</a>
                                <a href="#">Partner</a>
                                <a href="#">Portal</a>
                                
                              </div>
                                
                                <span>
                                @2002-2021 Yiğit Holding , Bütün hakları Saklıdır. 
                                </span>
                    
                                <ul>
                                    <li><YouTubeIcon></YouTubeIcon></li>
                                    <li><InstagramIcon></InstagramIcon></li>

                                    
                                </ul>
                    
                            </div>
                    </div>
                    </div>
                
                </div>
                
                </div>






        )
    }else{
        return(
            <div className="App_studyo">
                <div className="nav_studyo">
                                <div className="nav">
                                <Link to="/"><div className="log">
                                <img src="./img/creators.png" alt=""/>
                                </div></Link>
                                <ul>
                                <Link to="/studyo"><li>Stüdyo</li></Link>
                                <Link to="/ekipman"><li>Ekipman</li></Link>
                                
                                <Link to="/biz"><li>Biz</li></Link>
                                <Link to="/iletisim"><li>İletişim</li></Link>
                                    <li><ShoppingCartIcon></ShoppingCartIcon></li>
                                    <li><PersonIcon></PersonIcon></li>
                                    <li><SearchIcon></SearchIcon></li>
                                    <li><div className="burger" onClick={(e)=>{
                                        setNav(!Nav)
                                    }}>
                                        <div className="line"></div>
                                        
                                    </div></li>
                                    
                                    
                                    
                                    
                                    
                                    </ul>
                                    <div className="icons">
                                    <img src="./img/red.png" height="75" alt=""/>
                                    </div>
                                </div>
                </div>
                <div className="studyo_firsthalf">
                    <img src="./img/stüdyo/studyo.png" alt=""/>
                    <h1>Türkiyenin En Büyük Youtube Stüdyosuna <br/> Hoşgeldiniz...</h1>
                </div>

                <div className="studyo_secondhalf">
                    <img src="./img/deneme.jpg" alt=""/>
                        <div className="studyo_contentleft">
                            <h1>27 Yıllık Tecrübe artık Türkiye'de</h1>
                            <p>
                                27 Yıldır Televizyon yayıncılığı ve sosyal medya yayıncılığı yapan Yiğit Holding; Amerika , Avusturalya ve Güney Kore'den sonra artık Türkiye'de. Bütün imkanları ile başta Bursa'da sonra İstanbul ve tüm Türkiyede.
                                
                            </p>
                            
                            
                        </div>
                        
                </div>


                <div className="studyo_thirdhalf">
                    <div className="left">
                    <img src="./img/nikons.jpg" alt=""/>
                    </div>
                    <div className="studyo_contentright">
                        
                        <p>
                        <b>Creators</b> Türkiye'de bulunan başarılı yeteneklerimize yol arkadaşlığı ediyor aynı zamanda uzun zamandır edindiği deneyimleri onları geliştirmek ve daha ileriye götürmek için kullanıyor.

 <br/>

<b>Creators</b> Sosyal medyada video içeriği üreten veya üretmeyi hedefleyen yetenekli video bloggerlarımız için Profesyonel hizmetler sağlıyor ve onların yanında yer almaktan gurur duyuyor.


                        </p>
                    </div>
                </div>
                
                <div className="studyo_last">
                    <div className="row p-5">
                    <div className="col-lg-4">
                        <h1>Dijital Medya Stüdyo ve Prodüksiyon</h1>
                        <p> 

İhtiyacınız için verilen hizmetler arasında Yeni medya stüdyo ve prodüksiyon hizmetimizde yer almaktadır. Profesyonel stüdyo ve prodüksiyon hizmetimiz ile emin ellerdesiniz.</p>
                    </div>
                    <div className="col-lg-4">
                        <h1>Menajerlik Hizmeti</h1>
                        <p>İhtiyacınız için verilen hizmetler arasında Yeni medya stüdyo ve prodüksiyon hizmetimizde yer almaktadır. Profesyonel stüdyo ve prodüksiyon hizmetimiz ile emin ellerdesiniz.</p>
                    </div>
                    <div className="col-lg-4">
                        <h1>Mcn'lik Hizmeti</h1>
                        <p>Mcn'lik Hizmetimiz ile Dijital medya'ya ayak uydurmak artık Creators ile beraber daha eğlenceli.</p>
                    </div>
                    </div>
                </div>
            
                <div className="footer">
                              
                                <div className="mailing">
                                  <div className="al">
                                    Biz Size Ulaşalım
                                  </div>
                                  <div className="input">
                                    <input type="text" placeholder="E-Mailinizi Yazın"/>
                                  </div>
                                  <div className="button">
                                    Onayla
                                  </div>
                                </div>
                    
                                <ul>

                                  

                                <li><a href="http://www.creators.com.tr/">Creators</a></li>
                                  <li><Link to="/iletisim">İletişim</Link></li>
                                  <li>Basın</li>
                                  <li><Link  to="/iletisim">Yardım</Link></li>
                                  <li>Gizlilik</li>
                                  <li>Partner</li>
                                  <li>Portal</li>
                                </ul>
                                <span>
                                @2002-2021 Yiğit Holding , Bütün hakları Saklıdır. 
                                </span>
                    
                                <ul>
                                  <li><YouTubeIcon style={{ fontSize: 40 }}></YouTubeIcon></li>
                                  <li><InstagramIcon style={{ fontSize: 40 }}></InstagramIcon></li>

                                  
                                </ul>
                    
                            </div>
            </div>
        )
    }
}

export default Studyo
