import React , {useState,useEffect} from 'react'
import { useMediaQuery } from 'react-responsive';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { Link } from 'react-router-dom';

function Contact() {
    
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1224px)'
    })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    const [Nav, setNav] = useState(true)
    if(isTabletOrMobile){
         return (
<div className="mobil_studyo">
                <div className="nav_mobile">
                            <Link to="/">
                            <div className="log">
                              <img src="./img/creators1.png" alt=""/>
                            </div>
                            </Link>
                            
                            <div className="icons">
                              <ShoppingCartIcon></ShoppingCartIcon>
                              <PersonIcon></PersonIcon>
                              <SearchIcon></SearchIcon>
                              <div className="burger" onClick={(e)=>{
                                setNav(!Nav)
                              }}>
                                <div className="line"></div>
                                
                              </div>
                            </div>
                          </div>
                            <div className={Nav?"nav_scrol":"nav_scrol active"} >
                            <div className="row bul">
                              <div className="col-6 alt"><Link to="/studyo">Stüdyo
                              </Link></div>
                              <div className="col-6 alt"><Link to="/ekipman">Ekipman</Link></div>
                              
                              <div className="col-6 alt"><Link to="/biz">Biz</Link></div>
                              <div className="col-6 alt"><Link to="/iletisim">İletişim</Link></div>

                            </div>
                            </div>
                <div className="studyo_mobilcontent">
                    
                    <div className="studyo_secondhalf">
                        <img src="./img/dj2.jpg" alt=""/>
                        <div className="studyo_content">
                            <h1>Ekipmanlar</h1>
                            <p>
                                Bu kısımda Stüdyomuzda bulunan ve müşterilerimizin kullanımına açık ekipmanları bu kısımda bulabilirsiniz.Bu kısımdaki ekipmanların dışında bilgi almak için bize ulaşın.
                            </p>
                        </div>
                    </div>
                      <div className="sec">
                          <p></p>
                      </div>

                    <div className="thirdhalf">
                        <div className="row">
                        <div className="title">
                            <h1>
                                Kameralar
                            </h1>
                        </div>
                          <div className="col-12 cont">
                            <h1>Nikon</h1>
                            <p>Kurucu ortaklarımızdan Serkan Yiğit Dji Türkiye distüribitörüdür.Türkiyede bulunan bütün Dji dronlar bulunmaktadır.Detaylı bilgi için bize ulaşın..</p>
                          </div>
                          <div className="col-12 cont">
                          <h1>Dji</h1>
                          <p>  Kurucu ortaklarımızdan Serkan Yiğit Nikon Türkiye distüribitörüdür. Türkiyede bulunan bütün Nikon kameralar bulunmaktadır.Detaylı bilgi için bize ulaşın.</p>
                          </div>
                          <div className="col-12 cont">
                          <h1>Red</h1>
                          <p>  Red Markasının bütün profesyonel kameraları stüdyomuzda bulunmaktadır. Detaylı bilgi için bize ulaşın.</p>
                          </div>
                          <div className="title">
                              <h1>
                                  Mikrafonlar
                              </h1>
                          </div>
                          <div className="col-12 cont">
                            <h1>Rode</h1>
                            <p>
                            Rode markasının bütün yayıncılık ve içerik üreticileri için ürettiği bütün mikrafonları stüdyomuzda bulabilirsiniz. Detaylı bilgi için iletişime geçin.
                            </p>
                          </div>
                        </div>
                    </div>
                    <div className="contact">
                    <div className="kilit">
                    <div className="footer">
                                
                                <div className="mailing">
                                    
                                    <div className="input">
                                    <input type="text" placeholder="E-Mailinizi Yazın"/>
                                    </div>
                                    <div className="button">
                                    Onayla
                                    </div>
                                </div>
                    
                                

                                    

                                <div className="width">
                                <a href="http://www.creators.com.tr/">Creators</a>
                                <a href="/iletisim">İletişim</a>
                                <a href="#">Basın</a>
                                <a href="/iletisim">Yardım</a>
                              </div>
                              <div className="width">
                                <a href="#">Gizlilik</a>
                                <a href="#">Partner</a>
                                <a href="#">Portal</a>
                                
                              </div>
                                
                                <span>
                                @2002-2021 Yiğit Holding , Bütün hakları Saklıdır. 
                                </span>
                    
                                <ul>
                                    <li><YouTubeIcon></YouTubeIcon></li>
                                    <li><InstagramIcon></InstagramIcon></li>

                                    
                                </ul>
                    
                            </div>
                    </div>
                    </div>
                
                </div>
                    </div>
                
                





            
        )
    }else{
        return (
            <div className="App">
                            <div className="nav">
                                <Link to="/"><div className="log">
                                <img src="./img/creators.png" alt=""/>
                                </div></Link>
                                <ul>
                                <Link to="/studyo"><li>Stüdyo</li></Link>
                                <Link to="/ekipman"><li>Ekipman</li></Link>
                                
                                <Link to="/biz"><li>Biz</li></Link>
                                <Link to="/iletisim"><li>İletişim</li></Link>
                                <li><ShoppingCartIcon></ShoppingCartIcon></li>
                                <li><PersonIcon></PersonIcon></li>
                                <li><SearchIcon></SearchIcon></li>
                                <li><div className="burger" onClick={(e)=>{
                                    setNav(!Nav)
                                }}>
                                    <div className="line"></div>
                                    
                                </div></li>
                                
                                
                                
                                
                                
                                </ul>
                                <div className="icons">
                                <img src="./img/red.png" height="75" alt=""/>
                                </div>
                            </div>


                            

                        <div className="contact">
                        <div className="ekipman">
                               <div className="box1">
                                   <div className="topcontent">
                                        <img src="./img/dj2.jpg" alt=""/>
                                   </div>
                                   <div className="bottomcontent red">
                                    <p>
                                    Kurucu ortaklarımızdan Serkan Yiğit Dji Türkiye distüribitörüdür.Türkiyede bulunan bütün Dji dronlar bulunmaktadır.Detaylı bilgi için bize ulaşın.
                                    </p>
                                   </div>
                               </div>
                               <div className="box1">
                                    <div className="topcontent">
                                    <img src="./img/nikon2.jpg" alt=""/>
                                    </div>
                                    <div className="bottomcontent red">
                                    <p>
                                        Kurucu ortaklarımızdan Serkan Yiğit Nikon Türkiye distüribitörüdür. Türkiyede bulunan bütün Nikon kameralar bulunmaktadır.Detaylı bilgi için bize ulaşın.
                                    </p>
                                    </div>
                               </div>
                               <div className="box1">
                                    <div className="topcontent">
                                    <img src="./img/ranger-1.jpg" alt=""/>
                                    </div>
                                    <div className="bottomcontent red">
                                    <p>
                                        Red Markasının bütün profesyonel kameraları stüdyomuzda bulunmaktadır. Detaylı bilgi için bize ulaşın.
                                    </p>
                                    </div>
                               </div>
                        </div>
                            <div className="footer">
                                
                                <div className="mailing">
                                    <div className="al">
                                    Biz Size Ulaşalım
                                    </div>
                                    <div className="input">
                                    <input type="text" placeholder="E-Mailinizi Yazın"/>
                                    </div>
                                    <div className="button">
                                    Onayla
                                    </div>
                                </div>
                    
                                <ul>

                                    

                                <li><a href="http://www.creators.com.tr/">Creators</a></li>
                                  <li><Link to="/iletisim">İletişim</Link></li>
                                  <li>Basın</li>
                                  <li><Link  to="/iletisim">Yardım</Link></li>
                                  <li>Gizlilik</li>
                                  <li>Partner</li>
                                  <li>Portal</li>
                                </ul>
                                <span>
                                @2002-2021 Yiğit Holding , Bütün hakları Saklıdır. 
                                </span>
                    
                                <ul>
                                    <li><YouTubeIcon style={{ fontSize: 40 }}></YouTubeIcon></li>
                                    <li><InstagramIcon style={{ fontSize: 40 }}></InstagramIcon></li>

                                    
                                </ul>
                    
                            </div>
                        </div>
            </div>
        )
    }
   
}

export default Contact
