import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Home from './main';
import Contact from './contact'
import Studyo from './studyo'
import Ekipman from './ekipman'
import Biz from './biz'
function App() {
  return (
    <div>
      <Router>
          <Switch>
          <Route path="/studyo">
            <Studyo/>
          </Route>
          <Route path="/ekipman">
            <Ekipman/>
          </Route>
          <Route path="/biz">
            <Biz/>
          </Route>
          <Route path="/iletisim">
            <Contact/>
          </Route>
          <Route path="/" exact>
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  )
}

export default App
