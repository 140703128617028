import ReactPlayer from 'react-player'
import React , {useState,useEffect} from 'react'
import './App.css';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { useMediaQuery } from 'react-responsive';
import Data from './data.json';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import {Link} from 'react-router-dom';

function App() {

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const [Nav, setNav] = useState(true)
  const [counter, setCounter] = useState(1)
  const [bilgi, setbilgi] = useState([])
  const [changed, setchanged] = useState(false)
  const [timeout, setTimeout] = useState(1000)
  const [value, setValue] = useState(0);

  const onChange = value => {
  setValue(value);
  }

  /*value={Input} onChange={(e)=>{
                            setInput(e.target.value);
                        }} 
                        
                        <IconButton>
                        <EmojiEmotionsIcon/>
                    </IconButton>

                        <IconButton>
                        <MicIcon/>
                    </IconButton>

                        
                        
                        */

                    useEffect(() => {
                      if(value===4){
                        setValue(0)
                      }else if(value===-1){
                        setValue(3)
                      }
                    }, [value]);

                    useEffect(() => {
                      if(counter===5){
                        setCounter(1)
                      }else if(counter===0){
                        setCounter(4)
                      }
                    }, [counter]);
                    

                       if(isTabletOrMobile){
                        return (
                          <div className="mobil">
                            <div className="enter">
                              <video src="./img/video.mp4" playsInline="playsInline"  autoPlay loop muted/>
                              <div className="logo">
                                <img src="./img/tepecreators.png" alt="" srcset=""/>
                      
                                <h1>Youtube Stüdyosu</h1>
                                <h2>Creators Turkey Farkıyla.</h2>
                                <a href=""> Bize Katıl </a>
                              </div>
                      
                            </div>

                            <div className="nav_mobile">
                            <div className="log">
                              <img src="./img/creators1.png" alt=""/>
                            </div>
                            
                            <div className="icons">
                              <ShoppingCartIcon></ShoppingCartIcon>
                              <PersonIcon></PersonIcon>
                              <SearchIcon></SearchIcon>
                              <div className="burger" onClick={(e)=>{
                                setNav(!Nav)
                              }}>
                                <div className="line"></div>
                                
                              </div>
                            </div>
                          </div>
                            <div className={Nav?"nav_scrol":"nav_scrol active"} >
                            <div className="row bul">
                            <div className="col-6 alt"><Link to="/studyo">Stüdyo
                              </Link></div>
                              <div className="col-6 alt"><Link to="/ekipman">Ekipman</Link></div>
                              
                              <div className="col-6 alt"><Link to="/biz">Biz</Link></div>
                              <div className="col-6 alt"><Link to="/iletisim">İletişim</Link></div>

                            </div>
                            </div>
                      

                              <div className="mobil_content">
                              <div className="content_sub">
                                  <div className="header">
                                  <h1>{Data[value+1].name}</h1>
                                  <h3>{Data[value+1].sub_text}</h3>
                                </div>
                              </div>
                                <div className="lol">
                                
                                <Carousel plugins={['arrows']} value={value}
        onChange={onChange}>
                                  <iframe width="300" height="150" src="https://www.youtube.com/embed/HNv2Cbbb3dQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                  <iframe width="300" height="150" src="https://www.youtube.com/embed/CtRJOvYU3b8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                  <iframe width="300" height="150" src="https://www.youtube.com/embed/1ruZbvbdyXw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                  <iframe width="300" height="150" src="https://www.youtube.com/embed/-2IuprrwuHk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </Carousel>
                                  <div className="d">
                                  <div className="ok_sol" onClick={(e)=>{
                                      setValue(value-1)
                                    }}>

                                  </div>
                                    <div className="ok_sag" onClick={(e)=>{
                                      setValue(value+1)
                                    }}></div>
                                  </div>
                                </div>
                              
                                <div className="footer mt-5">
                              
                              <div className="mailing">
                                
                                <div className="input">
                                  <input type="text" placeholder="E-Mailinizi Yazın"/>
                                </div>
                                <div className="button">
                                  Onayla
                                </div>
                              </div>
                              
                              <div className="width">
                                <a href="http://www.creators.com.tr/">Creators</a>
                                <a href="/iletisim">İletişim</a>
                                <a href="#">Basın</a>
                                <a href="/iletisim">Yardım</a>
                              </div>
                              <div className="width">
                                <a href="#">Gizlilik</a>
                                <a href="#">Partner</a>
                                <a href="#">Portal</a>
                                
                              </div>
                              <span>
                              @2002-2021 Yiğit Holding , Bütün hakları Saklıdır. 
                              </span>
                  
                              <ul>
                                <li><YouTubeIcon></YouTubeIcon></li>
                                <li><InstagramIcon></InstagramIcon></li>
                                
                              </ul>
                  
                          </div>
                              
                              
                              </div>




                          </div>
                        );
                       }else{
                        return (
                          <div className="App">
                          <div className="enter">
                            <video src="./img/video.mp4" playsInline="" autoPlay loop muted/>
                            <div className="logo">
                              <img src="./img/tepecreators.png" alt="" srcset=""/>
                    
                              <h1>Youtube Stüdyosu</h1>
                              <h2>Creators Turkey Farkıyla</h2>
                              <a href=""> Bize Katıl </a>
                            </div>
                    
                          </div>
                    
                          <div className="nav">
                          <Link to="/"><div className="log">
                                <img src="./img/creators.png" alt=""/>
                                </div></Link>
                                <ul>
                                <Link to="/studyo"><li>Stüdyo</li></Link>
                                <Link to="/ekipman"><li>Ekipman</li></Link>
                                
                                <Link to="/biz"><li>Biz</li></Link>
                                <Link to="/iletisim"><li>İletişim</li></Link>
                              <li><ShoppingCartIcon></ShoppingCartIcon></li>
                              <li><PersonIcon></PersonIcon></li>
                              <li><SearchIcon></SearchIcon></li>
                              <li><div className="burger" onClick={(e)=>{
                                setNav(!Nav)
                              }}>
                                <div className="line"></div>
                                
                              </div></li>
                              
                              
                             <li></li>
                              
                              
                            </ul> 
                            <div className="log">
                              <div className="right"><img src="./img/red.png"  alt=""/></div>
                            </div>
                          </div>
                    
                          <div className="conten">
                            
                    
                            <div className="content_sub">
                              <div className="header">
                              <h1>Creators Türkiye</h1>
                              <h3>Bizimle içerik üretmeye hazır mısınız?
        
                              </h3>
                            </div>
                              
                              <div className="main">
                              <div className="row">


                                
                              <div className="col-lg-6 col"><div className="arrow_left" onClick={(e)=>{
                                    setCounter(counter-1)
                                    setchanged(!changed)
                                    setInterval(() => {
                                      setchanged(false)
                                    }, 5000);
                                    
                                  }}></div>
                                  <div className={changed?"loc act":"loc"}>
                                  <iframe width="550" height="300" src={Data[counter].link} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col">
                                  
                                  <div className={changed?"sss act":"sss"}>
                                  <h1 >{Data[counter].name}</h1>
                                    <div className="collective-entity-title">
                                    {Data[counter].sub_text}
                                    </div>
                                    <p>{Data[counter].text1}.
                                    </p><br/>
                                    <p>{Data[counter].text2}</p><br/>
                    
                                    <p>
                                    {Data[counter].text3}
                                    </p>
                                  </div>
                                  <div className="arrow_right" onClick={(e)=>{
                                    setCounter(counter+1)
                                    setchanged(true)
                                    setInterval(() => {
                                      setchanged(false)
                                      setTimeout(timeout+500)
                                      
                                    }, timeout);
                                    
                                  }}></div>
                                
                                
                                </div>
                                
                              </div>
                                
                                    
                              </div>
                            
                              
                            
                            </div>
                    
                            <div className="footer">
                              
                                <div className="mailing">
                                  <div className="al">
                                    Biz Size Ulaşalım
                                  </div>
                                  <div className="input">
                                    <input type="text" placeholder="E-Mailinizi Yazın"/>
                                  </div>
                                  <div className="button">
                                    Onayla
                                  </div>
                                </div>
                    
                                <ul>

                                  

                                  <li><a href="http://www.creators.com.tr/">Creators</a></li>
                                  <li><Link to="/iletisim">İletişim</Link></li>
                                  <li>Basın</li>
                                  <li><Link  to="/iletisim">Yardım</Link></li>
                                  <li>Gizlilik</li>
                                  <li>Partner</li>
                                  <li>Portal</li>
                                </ul>
                                <span>
                                @2002-2021 Yiğit Holding , Bütün hakları Saklıdır. 
                                </span>
                    
                                <ul>
                                  <li><YouTubeIcon style={{ fontSize: 40 }} ></YouTubeIcon></li>
                                  <li><InstagramIcon style={{ fontSize: 40 }} ></InstagramIcon></li>

                                  
                                </ul>
                    
                            </div>
                          </div>
                    
                        </div>
                        );
                       }
  
}

export default App;
